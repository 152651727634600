<template>
  <div class="relative">
    <div
      class="rounded-lg px-4 py-3 flex items-center justify-between border border-transparent hover:border-[#24B6E4]"
      :class="
        mode === 'dark' ? 'bg-[#1C1316] text-white' : 'bg-[#D9D9D9] text-black'
      "
      @click="isChildShow = !isChildShow"
    >
      <p v-if="modelVal !== null">
        {{ label ? options[modelVal][label] : options[modelVal] }}
      </p>
      <p v-else :class="mode === 'dark' ? 'text-[#909093]' : 'text-[#888]'">
        {{ placeholder }}
      </p>
      <van-icon name="arrow-down" />
    </div>
    <div
      class="absolute shadow w-full rounded-lg left-0 top-[3.2rem] leading-8 px-4 py-2 transform transition-all duration-150 z-[1]"
      :class="[
        mode === 'dark' ? 'bg-[#1C1316] text-white' : 'bg-[#D9D9D9] text-black',
        isChildShow
          ? 'translate-y-0 opacity-100 visible'
          : '-translate-y-3 opacity-0 invisible',
      ]"
    >
      <p
        v-for="(item, index) of options"
        :key="item"
        @click="emits('update:value', index), (isChildShow = false)"
        :class="{ 'text-[#24B6E4] font-medium': modelVal === index }"
      >
        {{ label ? item[label] : item }}
      </p>
      <p v-if="!options.length" class="text-[#888] text-center">无资料</p>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const isChildShow = ref(false);
const props = defineProps({
  mode: {
    type: String,
    default: 'dark',
  },
  placeholder: {
    type: String,
    default: '请选择游戏',
  },
  modelVal: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: [],
  },
  label: {
    type: String,
    default: null,
  },
});
const emits = defineEmits(['update:modelVal']);
</script>
<style lang=""></style>
